import { useRef, useEffect, useState } from "react";
import "../../../assets/scss/Home/Home.scss";
import CopyRight from "../../Components/CopyRight/CopyRight";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import About from "./Components/About/About";
import Banner from "./Components/Banner/Banner";
import Tokenomics from "./Components/Tokenomics/Tokenomics";
import Roadmap from "./Components/Roadmap/Roadmap";
import News from "./Components/News/News";
import HomeStaking from "./Components/HomeStaking/HomeStaking";
import Loader from "../../Components/Loader/Loader";

import bannerBg from '../../../assets/images/Home/Banner/bannerGif.gif';
import bannerShadow from '../../../assets/images/Home/Banner/bannerShadow.png';


const Home = () => {
   const [scrolly, setScrolly] = useState(false);
   return (
      <div className={`home_wrap ${scrolly ?"scrolled" : ""}`.trim()}>
         <Loader />
         <section className="homeBannerSec">
            <Header />
            <Banner />
            <img src={bannerShadow} className="bnrShadow" width="761" height="333" alt="bannerShadow" />
            <img src={bannerBg} className="bnrBg" width="1237" height="560" alt="bannerBg" />
         </section>
         <About />
         <Tokenomics />
         <HomeStaking />
         <Roadmap />
         <News />
         <Footer />
         <CopyRight />
      </div>
   )
}
export default Home;