import "../../../assets/scss/CopyRight/CopyRight.scss"

const CopyRight = () => {
   return (
         <div className="copyRightTx text-center" data-scroll-section>
            <div className="container">
               <p>Copyright &copy; 2024 Anacoin – All Rights Reserved.</p>
            </div>
         </div>
   )
}
export default CopyRight;