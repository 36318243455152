import { Link } from "react-router-dom";
import "../../../../../assets/scss/Home/News/News.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import newBanner1 from '../../../../../assets/images/Home/News/newBanner1.png';
import newBanner2 from '../../../../../assets/images/Home/News/newBanner2.png';
import newBanner3 from '../../../../../assets/images/Home/News/newBanner3.png';
import newBanner4 from '../../../../../assets/images/Home/News/newBanner4.png';
import { Parallax } from "react-scroll-parallax";

const News = () => {

   let settings = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      responsive: [
         {
           breakpoint: 1024,
           settings: {
             slidesToShow: 3,
             slidesToScroll: 1,
             infinite: true,
             dots: true
           }
         },
         {
           breakpoint: 600,
           settings: {
             slidesToShow: 2,
             slidesToScroll: 1,
             initialSlide: 2
           }
         },
         {
           breakpoint: 480,
           settings: {
             slidesToShow: 1,
             slidesToScroll: 1
           }
         }
       ]
    };    

   return (
      <section className="newsSec">
         <div className="container">
            <div className="abtHd">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="abtHdTls text-center">
                        <Parallax speed={3}>
                           <h2 className="secTl my-4">
                              <span className="txt-gradient">Latest News</span>
                           </h2>
                        </Parallax>
                     </div>
                  </div>
               </div>
            </div>
            <Slider {...settings} className="mt-5">
               <div className="">
                  <div className="newsCnt">
                     <img src={newBanner1} width="272" height="207" alt="newBanner1" className="img-fluid newsBanner" />
                     <h3>Blockchain</h3>
                     <h5>Nov 14 2023</h5>
                     <p>when an unknown printer took a of type and scrambled it to make</p>
                     <Link to="">Read More</Link>
                  </div>
               </div>
               <div className="">
                  <div className="newsCnt">
                     <img src={newBanner2} width="272" height="207" alt="newBanner1" className="img-fluid newsBanner" />
                     <h3>Blockchain</h3>
                     <h5>Nov 14 2023</h5>
                     <p>when an unknown printer took a of type and scrambled it to make</p>
                     <Link to="">Read More</Link>
                  </div>
               </div>
               <div className="">
                  <div className="newsCnt">
                     <img src={newBanner3} width="272" height="207" alt="newBanner1" className="img-fluid newsBanner" />
                     <h3>Blockchain</h3>
                     <h5>Nov 14 2023</h5>
                     <p>when an unknown printer took a of type and scrambled it to make</p>
                     <Link to="">Read More</Link>
                  </div>
               </div>
               <div className="">
                  <div className="newsCnt">
                     <img src={newBanner4} width="272" height="207" alt="newBanner1" className="img-fluid newsBanner" />
                     <h3>Blockchain</h3>
                     <h5>Nov 14 2023</h5>
                     <p>when an unknown printer took a of type and scrambled it to make</p>
                     <Link to="">Read More</Link>
                  </div>
               </div>
               <div className="">
                  <div className="newsCnt">
                     <img src={newBanner2} width="272" height="207" alt="newBanner1" className="img-fluid newsBanner" />
                     <h3>Blockchain</h3>
                     <h5>Nov 14 2023</h5>
                     <p>when an unknown printer took a of type and scrambled it to make</p>
                     <Link to="">Read More</Link>
                  </div>
               </div>
               <div className="">
                  <div className="newsCnt">
                     <img src={newBanner3} width="272" height="207" alt="newBanner1" className="img-fluid newsBanner" />
                     <h3>Blockchain</h3>
                     <h5>Nov 14 2023</h5>
                     <p>when an unknown printer took a of type and scrambled it to make</p>
                     <Link to="">Read More</Link>
                  </div>
               </div>
            </Slider>
         </div>
      </section>
   )
}
export default News;