import React, { useState } from 'react';
import { Link, NavLink, useLocation } from "react-router-dom";
import "../../../assets/scss/Header/Header.scss";

const Header = (props) => {

   const Location = useLocation();

   const sbShow = () => {
      document.querySelector('body').classList.toggle('sbMenu');
      document.querySelector('.smClose').classList.toggle('active');
   }

   return (
      <>
         <header className={`indHd ${Location.pathname == "/" ?"home" : ""}`.trim()}>
            <div className="container">
               <div className="row align-items-center">
                  <div className="col-3 col-lg-2 col-xl-3">
                     <div className="hdLogo">
                        <Link to="/">
                           <img src={require("../../../assets/images/logo.png")} alt="logo" className="img-fluid d-none d-sm-inline-block" />
                           <img src={require("../../../assets/images/logoLg.png")} width="40" height="40" alt="logo-mobile" className="img-fluid d-inline-block d-sm-none" />
                        </Link>
                     </div>
                  </div>
                  <div className="col-6 col-lg-7 col-xl-6">
                     <div className="hdMenuPt">
                        <ul className="hdMenu">
                           <li>
                              <Link to="/" className="active" onClick={sbShow}>Home</Link>
                           </li>
                           <li>
                              {/* <Link to="#abtSec" onClick={sbShow}>About us</Link> */}
                              <a href="#abtSec" onClick={sbShow}>About us</a>
                           </li>
                           <li>
                              {/* <Link to="/" onClick={sbShow}>Tokenomics</Link> */}
                              <a href="#tokenomicsSec" onClick={sbShow}>Tokenomics</a>
                           </li>
                           <li>
                              {/* <Link to="/" onClick={sbShow}>Roadmap</Link> */}
                              <a href="#roadmapSec" onClick={sbShow}>Roadmap</a>
                           </li>
                           <li>
                              {/* <Link to="/" onClick={sbShow}>Staking</Link> */}
                              <a href="#stakingSec" onClick={sbShow}>Staking</a>
                           </li>
                           <li>
                              <Link to="/" onClick={sbShow}>FAQ</Link>
                           </li>
                        </ul>
                     </div>
                  </div>
                  <div className="col-3">
                     <div className="hdRgt">
                        <Link to="/" className="btn btnLink p-0">
                           Register
                        </Link>

                        <Link to="/" className="btn btn-11545 btnGold">
                           Login
                        </Link>

                        <div className="mobMenu d-lg-none">
                           <button className="smClose" onClick={sbShow}>
                              <svg id="closeicon" viewBox="0 0 800 600">
                                 <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top"></path>
                                 <path d="M300,320 L460,320" id="middle"></path>
                                 <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) "></path>
                              </svg>
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </header>
      </>
   )
}
export default Header;