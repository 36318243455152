import "../../../../../assets/scss/Home/About/About.scss";
import futureSecurityIcon from "../../../../../assets/images/Home/About/futureSecurityIcon.png";
import futureLowFeeIcon from "../../../../../assets/images/Home/About/futureLowFeeIcon.png";
import futureBlockchainIcon from "../../../../../assets/images/Home/About/futureBlockchainIcon.png";
import futureEasyIcon from "../../../../../assets/images/Home/About/futureEasyIcon.png";
import limitedSupply from "../../../../../assets/images/Home/About/limitedSupply.png";
import fundRaising from "../../../../../assets/images/Home/About/fundRaising.png";
import referEarn from "../../../../../assets/images/Home/About/referEarn.png";
import staking from "../../../../../assets/images/Home/About/staking.png";
import anacoinIco from "../../../../../assets/images/Home/About/anacoinIco.png";
import just4ana from "../../../../../assets/images/Home/About/just4ana.png";
import { Parallax } from "react-scroll-parallax";

const About = () => {
   return (
         <div className="abt" id="abtSec">
            <div className="container">
               {/* <div className="w-100 my-3 my-md-5">
                  <div className="fullCardCnt">
                     <img src={anacoinIco} className='img-fluid' />
                  </div>                  
               </div> */}
               <div className="abtHd">
                  <div className="row mb-5">
                     <div className="col-lg-6">
                        <div className="abtHdTls">
                           <div className="secTlTg mb-3">
                              <span>About us</span>
                           </div>
                           <h2 className="secTl">
                              <span className="txt-gradient">Empowering the Future of Digital Finance</span>
                           </h2>
                        </div>
                     </div>
                     <div className="col-lg-6 mt-auto">
                        <div className="abtHdpr">
                           <p className="secPara">
                              The ANACOIN Vision: Your gateway to secure investment in the future of digital finance, also offering an opportunity to join and grow with a transparent, innovative, and revolutionary ecosystem.
                           </p>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-lg-6 d-flex">
                        <div className="futureCnt ylwCnt w-100">
                           <Parallax speed={3}>
                              <img src={limitedSupply} width="143" height="125" alt="futureSecurityIcon" />
                           </Parallax>
                           <div className="d-flex">
                              <div className="futureInnerCnt ml-auto">
                                 <h3 className="heading">Limited Supply</h3>
                                 <p>The finite supply is intended to create scarcity, where Investors and users may view this limited availability positively as it supports the token's long-term stability which can potentially increase its value over time due to supply and demand dynamics commonly observed in cryptocurrency markets.</p>
                              </div>
                           </div>                           
                        </div>
                     </div>
                     <div className="col-lg-6 d-flex">
                        <div className="futureCnt bluCnt w-100">
                           <Parallax speed={3}>
                              <img src={fundRaising} width="143" height="125" alt="futureLowFeeIcon" />
                           </Parallax>
                           <div className="d-flex">
                              <div className="futureInnerCnt ml-auto">
                                 <h3 className="heading">Fundraising</h3>
                                 <p>ANACOIN conducts a presale and Initial Coin Offering (ICO) as part of its fundraising strategy. This approach not only incentivizes early adoption but also provides crucial funding to support the development and growth of the ANACOIN ecosystem.</p>
                              </div>
                           </div>                           
                        </div>
                     </div>
                     <div className="col-lg-6 d-flex">
                        <div className="futureCnt bluCnt w-100">
                           <Parallax speed={3}>
                              <img src={referEarn} width="143" height="125" alt="futureBlockchainIcon" />
                           </Parallax>
                           <div className="d-flex">
                              <div className="futureInnerCnt ml-auto">
                                 <h3 className="heading">Refer & Earn Program</h3>
                                 <p>This program incentivizes word-of-mouth promotion which actively supports in organic growth by leveraging existing community members to attract new stakeholders potentially increasing Token utility and its adoption over time.</p>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-6 d-flex">
                        <div className="futureCnt ylwCnt w-100">
                           <Parallax speed={3}>
                              <img src={staking} width="143" height="125" alt="futureEasyIcon" />
                           </Parallax>
                           <div className="d-flex">
                              <div className="futureInnerCnt ml-auto">
                                 <h3 className="heading">Staking</h3>
                                 <p>Staking involves locking up ANACOIN tokens in a wallet or smart contract for a specified period. In exchange for staking, participants receive additional tokens as rewards, which can incentivize long-term holding and contribute to price stability.</p>
                              </div>
                           </div>                           
                        </div>
                     </div>
                  </div>
               </div>
               {/* <div className="w-100 my-3 my-md-5">
                  <div className="fullCardCnt">
                     <img src={just4ana} className='img-fluid' />
                  </div>                  
               </div> */}
            </div>
         </div>
   )
}
export default About;