import { Link } from "react-router-dom";
import "../../../assets/scss/Footer/Footer.scss";
import cloudIcon from "../../../assets/images/Footer/downloadCloud.png";
import anacoinAuditReport from '../../../assets/docs/AnacoinAuditReport.pdf';

const Footer = () => {
   return (
         <footer data-scroll-section>
            <div className="container">

               <div className="ftadRf">
                  <div className="adRfBx" data-scroll data-scroll-speed="1" data-scroll-offset="100,50%">
                     <div className="row align-items-center justify-content-between gy-3">
                        <div className="col-xl-8">
                           <div className="adRfBxTxIP">
                              <div className="adRfBxTx">Token Audit Report</div>
                              <div className="adRfBxIp">
                                 <p>Thorough evaluation of ANACOIN's security protocols and compliance standards. Ensuring the integrity and reliability of our token.</p>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-4 d-flex justify-content-xl-center">
                           <div className="adRfLk">
                              <a href={anacoinAuditReport} target="_blank" className="btn btn-20555 btn-blkOl">
                                 <img src={cloudIcon} height="32" width="32" alt="cloudIcon" className="mr-2" />Download
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="ftrBx">
                  <div className="row justify-content-between gy-4">
                     <div className="col-md-auto col-lg-auto order-2 order-lg-1">
                        <div className="ftMenu">
                           <h5 className="ftMenuTl">Quick Link</h5>
                           <ul>
                              <li>
                                 <Link to="/">About Us</Link>
                              </li>
                              <li>
                                 <Link to="/">Terms and Conditions</Link>
                              </li>
                              <li>
                                 <Link to="/">Privacy Policy</Link>
                              </li>
                              <li>
                                 <Link to="/">FAQ</Link>
                              </li>
                           </ul>
                        </div>
                     </div>
                     <div className="col-lg order-1 order-lg-2">
                        <div className="ftCon">
                           <div className="ftLogo">
                              <Link to="/">
                                 <img src={require("../../../assets/images/logo.png")} alt="logo" className="img-fluid" />
                              </Link>
                           </div>
                           <div className="ftTx">
                              <p>ANACOIN – Make your assets grow, where earnings find their way</p>
                           </div>
                           <div className="ftScl">
                              <ul>
                                 <li>
                                    <a href="/">
                                       <img src={require("../../../assets/icons/facebook.svg").default} alt="facebook" className="img-fluid" />
                                    </a>
                                 </li>
                                 <li>
                                    <a href="/">
                                       <img src={require("../../../assets/icons/telegram.svg").default} alt="telegram" className="img-fluid" />
                                    </a>
                                 </li>
                                 <li>
                                    <a href="/">
                                       <img src={require("../../../assets/icons/twitter.svg").default} alt="twitter" className="img-fluid" />
                                    </a>
                                 </li>
                                 <li>
                                    <a href="/">
                                       <img src={require("../../../assets/icons/linkedin.svg").default} alt="linkedin" className="img-fluid" />
                                    </a>
                                 </li>
                                 <li>
                                    <a href="/">
                                       <img src={require("../../../assets/icons/reddit.svg").default} alt="reddit" className="img-fluid" />
                                    </a>
                                 </li>
                                 <li>
                                    <a href="/">
                                       <img src={require("../../../assets/icons/instagram.svg").default} alt="instagram" className="img-fluid" />
                                    </a>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-auto col-lg-auto order-3">
                        <div className="ftMenu">
                           <h5 className="ftMenuTl">Contact Us</h5>
                           <ul>
                              <li>
                                 <Link to="/">info@octaverse.com</Link>
                              </li>
                              <li>
                                 <Link to="/">support@octaverse.com</Link>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>


            </div>
         </footer>
   )
}
export default Footer;