import { Route, Routes } from "react-router-dom";
import "./assets/scss/common.scss";
import 'locomotive-scroll/dist/locomotive-scroll.min.css';
import Home from "./Views/Pages/Home/Home";
import { ParallaxProvider } from "react-scroll-parallax";

function App() {
  return (
    <ParallaxProvider>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </ParallaxProvider>
  );
}

export default App;
