import "../../../../../assets/scss/Home/Roadmap/Roadmap.scss";
import roadmapBg from '../../../../../assets/images/Home/roadmapBg.png';

const Roadmap = () => {

   return (
      <section className="roadmapSec" id="roadmapSec">
         <div className="tknmcsUpperCnt">
            <div className="container">
               <div className="abtHd">
                  <div className="row">
                     <div className="col-lg-12">
                        <div className="abtHdTls text-center">
                           <h2 className="secTl my-5">
                              <span className="txt-gradient">Road Map</span>
                           </h2>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row justify-content-center">
                  <div className="col-lg-10 col-xl-9">
                     <div className="roadMapScrollCnt" id="roadMap">
                        <div className="roadMapRunnerCnt">
                           <div className="roadMapRunner"></div>
                        </div>
                        <div className="roadMapRow">
                           <div className="roadMapCnt" id="check1">
                              <div className="d-flex align-items-center">
                                 <span>(Q1-Q2)</span>
                                 <h3>2023</h3>
                              </div>
                              <ul>
                                 <li>Market Analysis & Feasibility Study</li>
                                 <li>Concept Inception</li>
                                 <li>Team Development</li>
                                 <li>Legal and Regulatory Compliance</li>
                              </ul>
                           </div>
                        </div>
                        <div className="roadMapRow">
                           <div className="roadMapCnt">
                              <div className="d-flex align-items-center">
                                 <span>(Q3-Q4)</span>
                                 <h3>2023</h3>
                              </div>
                              <ul>
                                 <li>Hiring Talent</li>
                                 <li>Whitepaper Developement</li>
                                 <li>Smart Contract Developement</li>
                                 <li>Pre-Marketing</li>
                                 <li>Branding</li>
                              </ul>
                           </div>
                        </div>
                        <div className="roadMapRow">
                           <div className="roadMapCnt">
                              <div className="d-flex align-items-center">
                                 <span>(Q1-Q2)</span>
                                 <h3>2024</h3>
                              </div>
                              <ul>
                                 <li>Strategic Partnership</li>
                                 <li>Internal Testing & Token Audit</li>
                                 <li>Official Website Launch</li>
                                 <li>Publishing Whitepaper</li>
                              </ul>
                           </div>
                        </div>
                        <div className="roadMapRow">
                           <div className="roadMapCnt">
                              <div className="d-flex align-items-center">
                                 <span>(Q3)</span>
                                 <h3>2024</h3>
                              </div>
                              <ul>
                                 <li>Private Sale</li>
                                 <li>Whitelisting</li>
                                 <li>Airdrop</li>
                                 <li>ICO Development</li>
                                 <li>Marketing Campaigns</li>
                              </ul>
                           </div>
                        </div>
                        <div className="roadMapRow">
                           <div className="roadMapCnt">
                              <div className="d-flex align-items-center">
                                 <span>(Q4)</span>
                                 <h3>2024</h3>
                              </div>
                              <ul>
                                 <li>Token Launch on Tier 2 Exchange</li>
                                 <li>Token Launch on CoinMarketCap & CoinGecko</li>
                                 <li>ICO Launch</li>
                                 <li>Staking & Token Distribution</li>
                              </ul>
                           </div>
                        </div>
                        <div className="roadMapRow">
                           <div className="roadMapCnt">
                              <div className="d-flex align-items-center">
                                 <span>(Q1-Q2)</span>
                                 <h3>2025</h3>
                              </div>
                              <ul>
                                 <li>Marketing & Outreach Campaigns</li>
                                 <li>Community Building</li>
                              </ul>
                           </div>
                        </div>
                        <div className="roadMapRow">
                           <div className="roadMapCnt">
                              <div className="d-flex align-items-center">
                                 <span>(Q3)</span>
                                 <h3>2025</h3>
                              </div>
                              <ul>
                                 <li>ANAPAY Integration</li>
                                 <li>ANAWALLET Integration</li>
                              </ul>
                           </div>
                        </div>
                        <div className="roadMapRow">
                           <div className="roadMapCnt">
                              <div className="d-flex align-items-center">
                                 <span>(Q4)</span>
                                 <h3>2025</h3>
                              </div>
                              <ul>
                                 <li>Listing on DEX</li>
                                 <li>Integration with DApps</li>
                              </ul>
                           </div>
                        </div>
                        <div className="roadMapRow">
                           <div className="roadMapCnt">
                              <div className="d-flex align-items-center">
                                 <span>(Q1-Q2)</span>
                                 <h3>2026</h3>
                              </div>
                              <ul>
                                 <li>Listing on Tier 1 Exchanges</li>
                                 <li>Innovation and R&D</li>
                              </ul>
                           </div>
                        </div>
                        <div className="roadMapRow">
                           <div className="roadMapCnt">
                              <div className="d-flex align-items-center">
                                 <span>(Q3-Q4)</span>
                                 <h3>2026</h3>
                              </div>
                              <ul>
                                 <li>Strategic Partnership Expansion with Octaverse Platform</li>
                                 <li>Strategic Partnership Expansion with third-party Platform</li>
                              </ul>
                           </div>
                        </div>
                        <div className="roadMapRow">
                           <div className="roadMapCnt">
                              <div className="d-flex align-items-center">
                                 <span>(Q1-Q4)</span>
                                 <h3>2027</h3>
                              </div>
                              <ul>
                                 <li>Community and Ecosystem growth</li>
                                 <li>Global Outreach</li>
                              </ul>
                           </div>
                        </div>
                        <div className="roadMapRow">
                           <div className="roadMapCnt">
                              <div className="d-flex align-items-center">
                                 <span>(Q1)</span>
                                 <h3>2028</h3>
                              </div>
                              <ul>
                                 <li>Token Burning Implementation</li>
                              </ul>
                           </div>
                        </div>
                        <div className="roadMapRow">
                           <div className="roadMapCnt">
                              <div className="d-flex align-items-center">
                                 <span>(Q1)</span>
                                 <h3>2029</h3>
                              </div>
                              <ul>
                                 <li>Sustainability and Scalability</li>
                              </ul>
                           </div>
                        </div>
                        <div className="roadMapRow cntr">
                           <div className="roadMapCnt">
                              <div className="d-flex justify-content-center align-items-center">
                                 <span>2030 & Beyond</span>
                              </div>
                              <ul>
                                 <li>GO ON TO BECOME A GLOBAL UTILITY PROJECT</li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <img src={roadmapBg} alt="roadmapBg" width="1440" height="1739" className="roadmapBg" />
      </section>
   )
}
export default Roadmap;