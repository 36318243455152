import { useEffect } from "react";
import { Link } from "react-router-dom";
import "../../../../../assets/scss/Home/Banner/Banner.scss";
import bannerHand from '../../../../../assets/images/Home/Banner/bannerHand.png';
import bannerCoin from '../../../../../assets/images/Home/bannerCoin.gif';
import handBanner from '../../../../../assets/images/Home/Banner/handBanner.png';
import bascCoinCopy from '../../../../../assets/images/Home/Banner/basc-coin-copy.mp4';
import anacoinWhitepaper from '../../../../../assets/docs/anacoinWhitepaper.pdf';
import { Parallax } from "react-scroll-parallax";

const Banner = () => {
   return (
         <div className="bnr d-lg-flex justify-content-center align-items-start">
            <div className="container">
               <div className="bnrTx text-center text-lg-left">
                  <div className="row">
                     <div className="col-lg-6 pr-0 pr-lg-4">
                        <h3>
                           <span>
                              Your Investment is Safe With
                           </span>
                        </h3>
                        <h1>
                           <span>ANACOIN</span>
                        </h1>
                        <p>Join the future of finance with ANACOIN and be part of a growing ecosystem that prioritizes safety, transparency, and innovation.</p>
                        <div className="d-flex justify-content-center justify-content-lg-start align-items-center flex-wrap">
                           <a href={anacoinWhitepaper} target="_blank" className="btn btnGoldOutline mr-3 my-1">Whitepaper</a>
                           <Link to="" className="btn btnGold my-1">Get Started</Link>
                        </div>
                     </div>                    
                  </div>
               </div>
            </div>
            <div className="bannerRightCnt">
               <div className="bannerInnerCnt">
                  {/* <img src={bannerHand} width="617" height="375" alt="bannerHand" className="bannerHand" />
                  <img src={bannerCoin} width="234" height="234" alt="bannerCoin" className="bannerCoin" />                   */}
                  <video autoPlay muted loop>
                        <source src={bascCoinCopy} type="video/mp4" />
                  </video>
               </div>
            </div>
         </div>
   )
}
export default Banner;